.App {
  text-align: center;
}

.container {
  margin-top: 30px;
}

.ant-spin-dot-item {
  background-color: #0596db !important;
}

.ambi-points {
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 24px */
}

.point {
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 48px */
}

.point-limit {
  text-align: center;
  font-size: 9px;
  font-style: normal;
}

.navigate-button {
  width: 320px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #1e2529;
  color: var(--white, #fff);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 16px */
  margin-bottom: 20px;
}

.navigate-button:hover,
.navigate-button:focus {
  color: var(--white, #fff);
  background-color: #1e2529;
  border-color: #1e2529;
}

.content-label {
  margin: 0px 16px 16px 16px;
  text-align: left;
  color: #1e2529;
  font-size: 16px;
  font-style: bold;
  font-weight: 700;
  line-height: 20px; /* 125% */
}
