.reward-card .ant-card-cover img {
  border-radius: 8px;
}

.reward-card.ant-card-bordered {
  box-shadow: none !important;
  border: none !important;
}
.reward-card .ant-card-body {
  padding: 0px 18px;
}

.reward-card .ant-card-meta-title {
  text-align: left;
  font-size: 14px;
  padding-top: 0px;
  margin-bottom: 0px !important;
}

.reward-card .ant-card-meta-description {
  text-align: left;
  font-size: 14px;
}

.reward-card .ant-card-cover img {
  border-radius: 8px;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  width: 140px;
  height: 140px;
  margin: auto;
  opacity: 0.2;
}
