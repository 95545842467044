.reward-button {
  margin-top: 35px;
  width: 320px;
  height: 69px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #1e2529;
  background: #231815;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
}

.reward-button:hover,
.reward-button:focus,
.reward-button:active {
  color: var(--white, #fff);
  background-color: #1e2529;
  border-color: #1e2529;
}

.maintenance {
  text-align: center;
  font-size: 12px;
  font-style: normal;
}

.maintenance-reactivation {
  text-align: center;
  font-size: 9px;
  font-style: normal;
}
