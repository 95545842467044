.reward-container {
  margin: 24px;
}
.reward-title {
  color: #1e2529;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 24px */
  text-align: left;
  margin-bottom: 0px;
}

.reward-points {
  color: #1e2529;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  margin-top: 6px;
}

.reward-description {
  color: #1e2529;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
}

.reward-company {
  color: #1e2529;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  margin-bottom: 0px;
}

.reward-url {
  color: var(--10001-blue, #0596db);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-top: 0px;
}

.reward-agreement {
  color: #1e2529;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.reward-logo {
  width: 320px;
  height: 115px;
  flex-shrink: 0;
}

.disabled-button {
  background-color: #ccc; /* 非活性時の背景色 */
  color: #666; /* 非活性時のテキスト色 */
  cursor: not-allowed; /* 非活性時のカーソル */
  pointer-events: none; /* クリックイベントを無効化 */
}
