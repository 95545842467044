.order-complete {
  color: #1e2529;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.order-description {
  color: #1e2529;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  margin-top: 6px;
  margin-left: 24px;
}

.reward-container {
  margin: 24px;
}
.reward-container-title {
  color: #1e2529;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  margin-bottom: 0px;
}

.reward-title {
  color: #1e2529;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-align: left;
  margin-top: 6px;
  margin-bottom: 0px;
}

.reward-points {
  color: #1e2529;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  margin-top: 6px;
}

.reward-description {
  color: #1e2529;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
}

.reward-company {
  color: #1e2529;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  margin-bottom: 0px;
}

.reward-url {
  color: var(--10001-blue, #0596db);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.reward-logo {
  width: 320px;
  height: 115px;
  flex-shrink: 0;
}
