.center-align {
  margin: 10px;
}

.right-align {
  text-align: right;
  flex: 1;
}

.left-align {
  text-align: left;
  flex: 1;
}

.check-icon {
  color: #0596db;
  font-size: 30px;
}
